import { Session } from '@remix-run/node';
import type { ReactElement } from 'react';
import { toast } from 'react-toastify';
import type { ToastOptions } from 'react-toastify';

const defaultOptions: ToastOptions = {
  hideProgressBar: true,
  // pauseOnFocusLoss: false,
  position: toast.POSITION.TOP_RIGHT,
  theme: 'dark',
  type: 'default',
};

/**
 * @name toastMessage
 * @description Little utility to trigger our toast notifications
 */
export const toastMessage = (
  message: string | ReactElement,
  options: ToastOptions = {},
) => {
  const opts = Object.assign(defaultOptions, options);

  return toast(message, opts);
};

/**
 * @name getToastSession
 * @description gets the currently set (if set) toast message in flashSession.
 */
export const getToastSession = async (flashSession: Session) => {
  const toast = flashSession.get('toast');
  flashSession.unset('toast');

  return toast;
};
